export default {
  name: 'Logout',
  async mounted() {
    await this.$router.replace('/auth/login');
  },
  render(h) {
    return h('div', {
      attrs: {
        class: '__loader text-center',
      }
    }, [
      h('div', {
        attrs: {
          class: '__loader-icon fa fa-spin fa-cog',
        }
      })
    ]);
  }
}
